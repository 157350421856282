import { defineMessages } from 'react-intl';

export const scope = 'contact';

export default defineMessages({
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Contact'
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First name'
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last name'
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email'
  },
  subject: {
    id: `${scope}.subject`,
    defaultMessage: 'Subject'
  },
  message: {
    id: `${scope}.message`,
    defaultMessage: 'Message'
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country'
  },
  fieldRequired: {
    id: `${scope}.fieldRequired`,
    defaultMessage: 'Field is required'
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Ask us anything!'
  },
  validEmail: {
    id: `${scope}.validEmail`,
    defaultMessage: 'Invalid email format'
  },
  subTitle: {
    id: `${scope}.subTitle`,
    defaultMessage:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled'
  }
});

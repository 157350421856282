import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Col, Row, Input, Button } from 'reactstrap';
import * as Yup from 'yup';
import { useIntl } from 'gatsby-plugin-intl';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import countries from '../constants/countries';
import { Layout } from '../components';
import messages from '../messages/contact';

const ContactForm = () => {
  const intl = useIntl();
  const [formResponse, setFormResponse] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    handleChange,
    values,
    setStatus,
    isSubmitting,
    handleSubmit,
    touched,
    errors
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      country: '',
      message: ''
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(
        intl.formatMessage(messages.fieldRequired)
      ),
      lastName: Yup.string().required(
        intl.formatMessage(messages.fieldRequired)
      ),
      email: Yup.string()
        .email(intl.formatMessage(messages.validEmail))
        .required(intl.formatMessage(messages.fieldRequired)),
      subject: Yup.string().required(
        intl.formatMessage(messages.fieldRequired)
      ),
      country: Yup.string().required(
        intl.formatMessage(messages.fieldRequired)
      ),
      message: Yup.string().required(intl.formatMessage(messages.fieldRequired))
    }),
    validateOnBlur: false,
    validateOnChange: false,

    onSubmit: async (data, { resetForm, setSubmitting }) => {
      try {
        const captchaResponse = await executeRecaptcha('contact');
        const formValues = {
          ...data,
          _wpcf7_recaptcha_response: captchaResponse
        };

        const formData = new FormData();
        /* eslint-disable no-restricted-syntax */
        for (const key of Object.keys(formValues)) {
          formData.append(key, formValues[key]);
        }

        const res = await fetch(
          '/api/wp-json/contact-form-7/v1/contact-forms/537/feedback',
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Accept: 'application/json'
            },
            method: 'POST',
            body: formData
          }
        );
        const jsonResponse = await res.json();
        resetForm({
          firstName: '',
          lastName: '',
          email: '',
          subject: '',
          country: 'Algeria',
          message: ''
        });
        setStatus({ success: true });
        setFormResponse(jsonResponse.message);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });
  return (
    <>
      <Row>
        <Col md="6">
          <div className="contact-title">
            {intl.formatMessage(messages.title)}
          </div>
          <div className="contact-sub-title">
            {intl.formatMessage(messages.subTitle)}
          </div>
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md="6">
            <Input
              className="mt-5"
              id="firstName"
              name="firstName"
              placeholder={intl.formatMessage(messages.firstName)}
              type="text"
              onChange={handleChange}
              value={values.firstName}
            />
            {errors.firstName && touched.firstName ? (
              <div className="contact-form-error fade-in">
                {errors.firstName}
              </div>
            ) : null}
          </Col>
          <Col md="6">
            <Input
              className="mt-5"
              id="lastName"
              name="lastName"
              placeholder={intl.formatMessage(messages.lastName)}
              type="text"
              onChange={handleChange}
              value={values.lastName}
            />
            {errors.lastName && touched.lastName ? (
              <div className="contact-form-error fade-in">
                {errors.lastName}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Input
              className="mt-5"
              id="email"
              name="email"
              placeholder={intl.formatMessage(messages.email)}
              type="text"
              onChange={handleChange}
              value={values.email}
            />
            {errors.email && touched.email ? (
              <div className="contact-form-error fade-in">{errors.email}</div>
            ) : null}
          </Col>
          <Col md="6">
            <Input
              className="mt-5"
              id="subject"
              name="subject"
              placeholder={intl.formatMessage(messages.subject)}
              type="text"
              onChange={handleChange}
              value={values.subject}
            />
            {errors.subject && touched.subject ? (
              <div className="contact-form-error fade-in">{errors.subject}</div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Input
              className="mt-5"
              type="select"
              id="country"
              name="country"
              placeholder={intl.formatMessage(messages.country)}
              onChange={handleChange}
              value={values.country}
            >
              {countries.map(country => {
                const countryName = Object.values(country).pop();
                return (
                  <option key={countryName} value={countryName}>
                    {countryName}
                  </option>
                );
              })}
            </Input>
            {errors.country && touched.country ? (
              <div className="contact-form-error fade-in">{errors.country}</div>
            ) : null}
          </Col>
          <Col md="6">
            <Input
              className="mt-5"
              type="textarea"
              id="message"
              name="message"
              placeholder={intl.formatMessage(messages.message)}
              rows="6"
              onChange={handleChange}
              value={values.message}
            />
            {errors.message && touched.message ? (
              <div className="contact-form-error fade-in">{errors.message}</div>
            ) : null}
            <Button
              type="submit"
              className="mt-5 w-100 contact-submit-btn"
              disabled={isSubmitting}
              size="lg"
            >
              Submit
            </Button>
            {formResponse && <div>{formResponse}</div>}
          </Col>
        </Row>
      </form>
    </>
  );
};
const Contact = () => (
  <Layout>
    <ContactForm />
  </Layout>
);

export default Contact;

module.exports = [
  { DZA: 'Algeria' },
  { AGO: 'Angola' },
  { BEN: 'Benin' },
  { BWA: 'Botswana' },
  { BFA: 'Burkina Faso' },
  { BDI: 'Burundi' },
  { CPV: 'Cabo Verde' },
  { CMR: 'Cameroon' },
  { CAF: 'Central African Republic' },
  { COG: 'Congo' },
  { CIV: "Côte d'Ivoire" },
  { COM: 'Comoros' },
  { COD: 'Democratic Republic of the Congo' },
  { DJI: 'Djibouti' },
  { EGY: 'Egypt' },
  { GNQ: 'Equatorial Guinea' },
  { ERI: 'Eritrea' },
  { ETH: 'Ethiopia' },
  { GAB: 'Gabon' },
  { GMB: 'Gambia' },
  { GHA: 'Ghana' },
  { GIN: 'Guinea' },
  { GNB: 'Guinea-Bissau' },
  { KEN: 'Kenya' },
  { LSO: 'Lesotho' },
  { LBR: 'Liberia' },
  { LBY: 'Libya' },
  { MDG: 'Madagascar' },
  { MWI: 'Malawi' },
  { MLI: 'Mali' },
  { MRT: 'Mauritania' },
  { MUS: 'Mauritius' },
  { MAR: 'Morocco' },
  { MOZ: 'Mozambique' },
  { NAM: 'Namibia' },
  { NER: 'Niger' },
  { NGA: 'Nigeria' },
  { REU: 'Réunion' },
  { RWA: 'Rwanda' },
  { STP: 'Sao Tome and Principe' },
  { SEN: 'Senegal' },
  { SYC: 'Seychelles' },
  { SLE: 'Sierra Leone ' },
  { SOM: 'Somalia' },
  { ZAF: 'South Africa' },
  { SSD: 'South Sudan' },
  { SDN: 'Sudan' },
  { TZA: 'Tanzania' },
  { TGO: 'Togo' },
  { TUN: 'Tunisia' },
  { UGA: 'Uganda' },
  { ESH: 'Western Sahara' },
  { ZMB: 'Zambia' },
  { ZWE: 'Zimbabwe' }
];
